export enum PricingPlanColumnTitles {
  Feature = "Feature",
  Free = "Free",
  Developers = "Developers",
  Business = "Businesses",
}

export interface PricingPlanFeatureOpts {
  credits?: string;
  enhancedSolanaAPIs?: string | boolean;
  webhooks?: string;
  autoScaling?: boolean;
  support?: string;
  priorityContactProtocolParsing?: boolean;
  earlyFeatureAccess?: boolean;
  overage?: string;
}

export interface PricingPlanPricingOpts {
  month: number;
  annual: number;
}

export const PricingPlanFeatureLabels: Map<
  keyof PricingPlanFeatureOpts,
  string
> = new Map([
  ["credits", "Credits"],
  ["enhancedSolanaAPIs", "Enhanced Solana APIs"],
  ["webhooks", "Datastreams"],
  ["support", "Support"],
  ["overage", "Overage"],
  ["priorityContactProtocolParsing", "Priority Contact/Protocol Parsing"],
  ["earlyFeatureAccess", "Early Feature Access"],
]);

export type PricingPlanBillingType = "annual" | "monthly" | "metered";

export interface PricingPlan {
  id: string;
  name: string;
  features: Record<string, string>;
  prices: Record<
    PricingPlanBillingType,
    {
      id: string;
      currency: string;
      /** Only set if billing scheme is per_unit. E.g. metered will not have a price. */
      price: number | null;
    }
  >;
}
