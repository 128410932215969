import type { ReactNode } from "react";
import React from "react";
import { twMerge } from "tailwind-merge";

type ButtonStyle = "default" | "primary" | "dotted" | "transparent";
type ButtonSize = "small" | "medium" | "large";
type OutlineStyle = boolean | 1 | 2; // Units in px. Should default to 1px when `true`

const getBackgroundColor = (style: ButtonStyle) => {
  switch (style) {
    case "primary":
      return "bg-hmio-blue-500";
    case "dotted":
      return "bg-hmio-black-700";
    case "transparent":
      return "bg-hmio-black-DEFAULT";
    default:
      return "bg-hmio-black-700";
  }
};

const getHoverColor = (style: ButtonStyle, outline: OutlineStyle) => {
  switch (style) {
    case "primary":
      return outline ? "hover:shadow-hmio-blue-600" : "hover:bg-hmio-blue-600";
    default:
      return "hover:opacity-80";
  }
};

const getOutlineColor = (style: ButtonStyle) => {
  switch (style) {
    case "primary":
      return "shadow-hmio-blue-500";
    case "dotted": // TODO change this to `outline` prop
      return "border-dashed	border-hmio-black-700 border";
    default:
      return "shadow-hmio-black-700";
  }
};

const getOutlineWidth = (outline: OutlineStyle) => {
  switch (outline) {
    case 2:
      return "shadow-inner-border-2";
    default:
      return "shadow-inner-border-1";
  }
};

const getSize = (size: ButtonSize) => {
  switch (size) {
    case "small":
      return "px-4 py-2 text-sm font-medium";
    case "medium":
      return "text-sm py-2.5 px-6 font-medium";
    case "large":
      return "text-md py-2.5 px-6 font-medium";
    default:
      return "text-sm py-2.5 px-6 font-semibold";
  }
};

interface ButtonProps {
  style?: ButtonStyle;
  outline?: OutlineStyle;
  disabled?: boolean;
  inactive?: boolean;
  children?: ReactNode;
  tooltip?: string;
  sizing?: ButtonSize;
  onClick?: () => void;
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
  type?: "submit" | "button" | "reset";

  /** Provide tailwind style className props */
  className?: string;
  disabledClassName?: string;

  /** Provide tailwind style className props for button active state */
  activeClassName?: string;
  isActive?: boolean;
}

/**
 * Props:
 *
 * `style` -- Color of the button
 *
 * `outline` -- Outline button text instead of solid bg color
 *
 * `disabled` -- Whether or not this button is disabled
 *
 * `inactive` -- A non-active button
 *
 * `tooltip` -- Helptext to display
 *
 * `onClick` -- Action function to call when the button is clicked
 *
 * `type` -- 'submit', 'button', or 'reset'. See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
 *
 */
export default function Button({
  style = "default",
  sizing = "medium",
  outline = false,
  disabled = false,
  inactive = false,
  isActive = false,
  tooltip,
  onClick,
  children,
  type,
  className,
  disabledClassName,
  activeClassName,
  ...props
}: ButtonProps & React.HTMLProps<HTMLButtonElement>) {
  const getState = () => {
    if (inactive) return "bg-hmio-black-900 text-hmio-black-400";
    if (disabled)
      return twMerge("opacity-30 cursor-not-allowed", disabledClassName);
    return `${getHoverColor(style, outline)} hover:ease-linear duration-75`;
  };

  const handleClick = () => {
    if (!disabled) onClick?.();
  };

  const mergedClassName = twMerge(
    `
      select-noneselect-none
      inline-block
      rounded-full
      text-center
      text-hmio-white
      font-medium
      ${
        outline
          ? `${getOutlineWidth(outline)} ${getOutlineColor(style)}`
          : getBackgroundColor(style)
      }
      ${getSize(sizing)}
    `,
    !isActive ? className : activeClassName,
  );

  return (
    <button
      {...props}
      title={tooltip}
      onClick={handleClick}
      type={type}
      className={`
        ${mergedClassName}
        ${getState()}
        `}
    >
      {children}
    </button>
  );
}
