import React from "react";
import { BarLoader } from "react-spinners";
import styled from "styled-components";

const ChartLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #aeb2c6;
  background: var(--hmio-black-900);
  z-index: 2;
`;

export default function Loader() {
  return (
    <ChartLoader>
      <BarLoader color="var(--hmio-white)" />
    </ChartLoader>
  );
}
